import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import {useTitle} from "../hooks/common";
import LeagueStandings from "../components/LeagueStandings";
import MatchupAwards from "../components/MatchupAwards";
import ScoreClub, {AwardSort} from "../components/ScoreClub";

export default function Home() {
    useTitle("Family Matters Fantasy Football League | FMFFL");

    return (
        <Container className="px-4 my-5 pt-3">
            <Row>
                <Col sm={12} className="text-center">
                    <h1 className="font-weight-light">Family Matters Fantasy Football League</h1>
                    <hr/>
                </Col>
            </Row>
            <LeagueStandings/>
            <MatchupAwards/>
            <ScoreClub title="Best Scores" sort={AwardSort.DESC} limit={5} />
            <ScoreClub title="Worst Scores" sort={AwardSort.ASC} limit={5} />
        </Container>
    );
}
